import axios from 'axios';
import dot from 'dot-object';
import { useRouter } from 'next/router';
import { useCallback, useRef, useState } from 'react';
import { JsonObject } from '../base';
import { API_URL } from '../config/config';
import { isObject } from './misc';

export function useIsFirstRender(): boolean {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

export const useAxios = ({ redirectOnUnauthorized = true } = {}) => {
  const router = useRouter();
  const catcher = useCallback(
    (error) => {
      if (error.response.status === 401 && redirectOnUnauthorized) {
        router.replace('/login');
        throw error;
      }
      throw error;
    },
    [redirectOnUnauthorized]
  );
  axios.defaults.withCredentials = true;
  return {
    get: <T>(url, params = {}) =>
      axios
        .get<T>(url, { params })
        .catch(catcher)
        .then((res) => res.data),
    post: <T>(url, params = null) =>
      axios.post<T>(url, params).then((res) => res.data),
    patch: (url, params = null) =>
      axios.patch(url, params).then((res) => res.data),
  };
};

const parseChangedFilesData = (content) => {
  const filesToUpload = isObject(content) ? {} : [];
  Object.entries(content).forEach(
    ([key, element]: [
      string,
      string | { inputValue: string; id: string; inputFiles: Array<string> }
    ]) => {
      if (
        element &&
        typeof element === 'object' &&
        'inputValue' in element &&
        element.inputValue &&
        element?.inputFiles[0] &&
        !element?.id
      ) {
        // eslint-disable-next-line prefer-destructuring
        filesToUpload[key] = element.inputFiles[0];
      } else if (Array.isArray(element) || isObject(element)) {
        const result = parseChangedFilesData(element);
        const jsonResult = JSON.stringify(result);
        if (jsonResult !== '{}' && jsonResult !== '[]') {
          filesToUpload[key] = result;
        }
      }
    }
  );
  return filesToUpload;
};

const setFilesRetourInData = (
  data,
  retour
): [JsonObject, Record<string, string>] => {
  const modifData = { ...data };
  const errorsData = {};
  const dottedRetour = dot.dot(retour);
  const goodKeys = Object.keys(dottedRetour).filter(
    (e) => e.slice(-3) === '.id'
  );
  const badKeys = Object.keys(dottedRetour).filter(
    (e) => e.slice(-6) === '.error'
  );
  goodKeys.forEach((e) => {
    dot.copy(e, e, retour, modifData);
  });
  badKeys.forEach((e) => {
    dot.copy(e, e.replace('.error', ''), retour, errorsData);
  });
  return [modifData, errorsData];
};

export const useFileUpload = (claimType) => {
  const [isLoading, setIsLoading] = useState(false);

  const upload = useCallback(
    async (files) => {
      if (!files || Object.keys(files).length === 0) return {};
      const bodyFormData = new FormData();
      const dottedFilesList = dot.dot(files);
      Object.entries(dottedFilesList).forEach(([key, value]) =>
        bodyFormData.append(key, value as string)
      );
      setIsLoading(true);
      try {
        const retour = await axios.post(
          `${API_URL}/claims/${claimType}/upload`,
          bodyFormData,
          { withCredentials: true }
        );
        setIsLoading(false);
        return retour.data.files;
      } catch (error) {
        setIsLoading(false);
        return {};
      }
    },
    [claimType]
  );

  const handleFiles = async (data) => {
    const filesData = parseChangedFilesData(data);
    const filesRetour = await upload(filesData);
    return setFilesRetourInData(data, filesRetour);
  };

  return [handleFiles, isLoading] as [
    (d: unknown) => Promise<[JsonObject, Record<string, string>]>,
    boolean
  ];
};

export default {};
