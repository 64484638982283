import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import getConfig from 'next/config';

require('dayjs/locale/fr');

dayjs.locale('fr');
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Europe/Paris');

const { publicRuntimeConfig } = getConfig();

const sitesConfigs: Record<
  string,
  { name: string; headLogo: string; authLogo: string; favicon?: string }
> = {
  caarl: {
    name: 'Caarl Assurance',
    headLogo: '/images/caarl_logo.svg',
    authLogo: '/images/caarl_logo.svg',
  },
  avopoints: {
    name: 'Avopoints',
    headLogo: '/sites/avopoints/logo_head.svg',
    authLogo: '/sites/avopoints/logo_auth.svg',
    favicon: '/sites/avopoints/favicon.ico',
  },
};

export const SITE_CONFIG =
  sitesConfigs[publicRuntimeConfig.SITE_CONFIG] || sitesConfigs.caarl;

export const { ACTIROUTE_URL, API_URL, MAINTENANCE_MODE, DEBUG } =
  publicRuntimeConfig;

export const MEETING_API_URL =
  publicRuntimeConfig.MEETING_API_URL || `${API_URL}/meetings`;
