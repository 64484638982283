import { SITE_CONFIG } from '../../config/config';

const Maintenance = () => (
  <div className="">
    <div className="block mx-auto max-w-sm px-5 md:px-0 mt-10">
      <p className="text-center text-lg">
        La plateforme {SITE_CONFIG.name} est actuellement en maintenance.
        <br />
        Nous serons de retour d&apos;ici peu.
      </p>
    </div>
  </div>
);
export default Maintenance;
