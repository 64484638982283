import Router from 'next/router';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { BaseAPIResponse, UserData } from '../../base';
import { API_URL } from '../../config/config';
import { useAxios } from '../../utils/hooks';
import { CheckTokenReturn, UserHookReturn, useUserArgs } from './types';

const getLogout = (axiosFetcher, mutateUser) => async (): Promise<void> => {
  await axiosFetcher.get(`${API_URL}/auth/logout`);
  mutateUser(`${API_URL}/user/me`);
  window.localStorage.setItem('logout', `${Date.now()}`); // new
  Router.push('/login');
};

export const useUser = ({
  redirectTo = null,
  redirectIfFound = false,
}: useUserArgs = {}): UserHookReturn => {
  const [isRedirected, setRedirected] = useState(false);
  const axiosFetcher = useAxios({ redirectOnUnauthorized: false });
  const {
    data: userResponse,
    mutate: mutateUser,
    error,
  } = useSWR<UserData>(`${API_URL}/auth/me`, axiosFetcher.get, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || (!userResponse && !error)) return;

    if (userResponse?.level === 'main' && !error) {
      Router.push('/subscriptions');
      setRedirected(true);
    }

    if (
      // If redirectTo is set, redirect if the user was not found.
      (!!redirectTo && !redirectIfFound && (!userResponse || error)) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && userResponse && !error)
    ) {
      Router.push(redirectTo);
      setRedirected(true);
    }
  }, [userResponse, error, redirectIfFound, redirectTo]);

  const isLoading = !userResponse && !error;

  return {
    user: userResponse,
    logout: getLogout(axiosFetcher, mutateUser),
    mutateUser,
    isRedirected,
    isLoading,
  };
};

export const useCheckToken = (token: string): CheckTokenReturn => {
  const axiosFetcher = useAxios();
  const { data: tokenResponse, error } = useSWR<BaseAPIResponse>(
    [`${API_URL}/auth/check-reset-token`, { token }],
    axiosFetcher.post,
    {
      revalidateOnFocus: false,
      errorRetryCount: 2,
    }
  );

  const isLoading = !tokenResponse && !error;

  return {
    isLoading,
    isValid: !error && tokenResponse?.status === 'ok',
  };
};

export default { useUser, useCheckToken };
