export const pick = (object, keys) =>
  keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      const retour = { ...obj };
      retour[key] = object[key];
      return retour;
    }
    return obj;
  }, {});

export const sleep = (time) =>
  new Promise((res) => {
    window.setTimeout(res, time);
  });

export const formatNumber = (num) =>
  num !== 0 ? num.toFixed(2).replace('.', ',') : '0';

export function isObject(item) {
  return (
    item && typeof item === 'object' && !Array.isArray(item) && item !== null
  );
}
