import { useMemo } from 'react';
import BlankScreen from '../structure/BlankScreen';
import UserContext from './context';
import { useUser } from './hooks';
import { ChildrenProps, UserHookReturn } from './types';

export const AuthContainer = ({ children }: ChildrenProps) => {
  const { user, mutateUser, logout, isLoading, isRedirected }: UserHookReturn =
    useUser({
      redirectTo: '/login',
    });

  const providerValue = useMemo(
    () => ({ user, mutateUser, logout, isLoading, isRedirected }),
    [user, mutateUser]
  );

  if (isLoading || isRedirected) {
    return <BlankScreen />;
  }

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};

export default { AuthContainer };
