import '../../css/fonts.css';
import '../../css/style.css';

import React from 'react';
import { withRedux } from '../redux/withRedux';
import { MAINTENANCE_MODE } from '../config/config';
import Maintenance from '../components/structure/Maintenance';
import { AuthContainer } from '../components/auth';

const App = ({ Component, pageProps }) => {
  if (MAINTENANCE_MODE) return <Maintenance />;

  if (Component.needAuth)
    return (
      <AuthContainer>
        <React.StrictMode>
          <Component {...pageProps} />
        </React.StrictMode>
      </AuthContainer>
    );

  return <Component {...pageProps} />;
};

App.getInitialProps = () => {
  // will be passed to the page component as props
};

export default withRedux(App, { ssr: false });
